const pages = {
  signin: 'signin',
  recoverPassword: 'recover-password',
  admin: '',
  dashboard: 'dashboard',
  users: 'users',
  // authUsers: 'auth-users',
  devices: 'devices',
  blackList: 'black-list',
  // policies: 'policies',
  // groups: 'groups',

  // resellerDocuments: 'reseller-documents',
  // customerDocuments: 'customer-documents',
  ivrs: 'ivrs',
  cdr: 'cdr',
  totalCDR: 'total-cdr',
  agentsCDR: 'agents-cdr',
  queuesCDR: 'queues-cdr',
  locations: 'locations',
  teams: 'teams',
  numbers: 'numbers',
  tenant: 'tenant',
  // context: 'context',
  trunk: 'trunk',
  // sipTemplate: 'sip-template',
  // sipTransport: 'sip-transport',
  incalls: 'incalls',
  outcalls: 'outcalls',
  voicemails: 'voicemails',
  agents: 'agents',
  musics: 'musics',
  soundfiles: 'sound-files',
  queues: 'queues',
  skillrules: 'skill-rules',
  skills: 'skills',
  // providerManagement: 'provider-management',
  // lines: 'lines',
  conferences: 'conferences',
  callPermissions: 'call-permissions',
  callPickups: 'call-pickups',
  schedules: 'schedules',
  bSFilters: 'bs-filters',
  ringGroups: 'ring-groups',
  // sessions: 'sessions',
  funckeys: 'funkeys',
  ingresses: 'ingresses',
  meetings: 'meetings',
  queueFeatures: 'queue-features',
  survey: 'survey',

  callInfos: 'call-infos',
  tags: 'tags',

  // resellers: 'resellers',
  // customers: 'customers',
  // resellerContacts: 'reseller-contacts',
  // customerContacts: 'customer-contacts',
  // resellerAddresses: 'reseller-addresses',
  // customerAddresses: 'customer-addresses',
  // contactAddresses: 'contact-addresses',
  // globalSettings: 'global-settings',
  // devicePlugin: 'device-plugin',
  // provisioningRegistrars: 'provisioning-registrars',
  // soundFilesSystem: 'sound-files-system',
  tenants: 'tenants',
  // provisioningConfifDevice: 'provisioning-confif-device',
  password: 'رمز عبور',
  // featureAccess: 'feature-access',
  // pjsipTransport: 'pjsip-transport',
  visualBuilder: 'visual-builder',
  license: 'licenses',
  licenseType: 'license-types',
  licenseCategory: 'license-categories',
  // phoneServiceProviders: 'phone-service-providers',
  // extensions: 'extensions',
  switchboards: 'switchboards',
  applications: 'applications',
  parkingLots: 'parking-lots',
  pagings: 'pagings',
  phonebookConfigurations: 'phonebook-configurations',
  phonebookContacts: 'phonebook-contacts',
  // directorySources: 'directory-sources',
  // directoryProfile: 'directory-profile',
  webhooks: 'webhooks',
  webhookLogs: 'webhook-logs',
  // extensionFeatures: 'extension-features',
  // plugins: 'plugins',
  signUp: 'sign-up',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  login: 'login',
  profile: 'profile',
  wizard: 'wizard',
  contextWizard: 'wizard-context',
  incallWizard: 'wizard-incall',
  outcallWizard: 'wizard-outcall',
  trunkWizard: 'wizard-trunk',
  queuesWizard: 'wizard-queues',
  ivrWizard: 'wizard-ivr',

  wizardTabs: 'wizard-tabs',
  CEL: 'cel',
};

export default pages;
